<template>
  <div
    class="flex rounded-full cursor-pointer text-sm font-medium bg-gray-500 items-center select-none text-white hover:bg-opacity-80 px-2.5 py-1"
    @click="$emit('close')"
  >
    <div class="rounded-xl bg-white overflow-hidden">
      <img v-if="user && getPhotoUrl" :src="getPhotoUrl" class="brightness-105 contrast-125 h-6 w-6 " />
      <div v-else-if="user" class="h-6 w-6 text-center p-1  flex flex-col justify-center items-center text-gray-500">
        {{ user.initials }}
      </div>
      <div v-else class="h-6 w-6 text-center p-1  flex flex-col justify-center items-center">
        <svg
          class="fill-current h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M5 20h14v2H5v-2zm7-2a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-2a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
        </svg>
      </div>
    </div>
    <div class="truncate text-ellipsis w-10 ml-2">
      {{ user.display_name || user.full_name }}
    </div>
    <FwIconClose class="w-5 h-5" />
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  name: 'UserTag',
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    getPhotoUrl() {
      if (!this.user) return false
      const sizeMap = {
        xs: 'small',
        sm: 'small',
        md: 'medium',
        lg: 'medium',
        xl: 'large',
        full: 'large',
        auto: 'medium',
      }
      return ServiceStorage.getUserImageViewUrl(this.user, sizeMap[this.size])
    },
  },
}
</script>
